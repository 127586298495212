<!-- 🌈🌈🌈🌈🌈🌈🌈🌈🌈🌈🌈🌈🌈🌈🌈2024 New Year's Countdown 🌈🌈🌈🌈🌈🌈🌈🌈🌈🌈🌈🌈🌈🌈🌈🌈🌈-->
<template>
  <div>
    <h1 id="myTime" class="time">{{ time }}</h1>

    <div id="myAud">
      <audio id="Audio" controls autoplay>
        <source src="../assets/never.mp3" type="audio/mp3">
      </audio>
    </div>

    <!-- <div class="aline"> -->
    <h1 class="words">What a "<span class="adj">{{ adj }}</span>" year!!</h1>
    <!-- </div> -->

    <div>
      <div class="bubutton">
        <button-view>Reset</button-view>
      </div>
    </div>

    <div class="ininput">
      <input-view v-model="adj" class="input" name="text" placeholder="Type here..." type="text" />
    </div>


  </div>

</template>
<script setup>
import { ref } from 'vue';
import InputView from '../components/input-view.vue';
import ButtonView from '../components/button-view.vue';
import confetti from 'canvas-confetti';


// onmousedown = () => {
//   enterFullscreen();
// }

// const enterFullscreen = () => {
//   const element = document.documentElement; // 全屏整个页面
//   if (element.requestFullscreen) {
//     element.requestFullscreen();
//   } else if (element.webkitRequestFullscreen) { // Safari
//     element.webkitRequestFullscreen();
//   } else if (element.msRequestFullscreen) { // IE
//     element.msRequestFullscreen();
//   }
// }



const adj = ref('Gorgeous');

//定义一个距离2025年1月29日00:00:00的倒计时
const countdown = () => {
  const now = new Date();
  const target = new Date('2025-01-29T00:00:00');
  const diff = target - now;
  if (diff > 0) {
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)); // 修改为let
    let seconds = Math.floor((diff % (1000 * 60)) / 1000); // 修改为let

    // 格式化分钟和秒数
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;

    return `${days} ${hours}:${minutes}:${seconds}`;
  }
  return '0 0:00:00';
};
const time = ref(countdown());
const startCelebration = (timerId) => {

  clearInterval(timerId);
  time.value = 'Neeeeeew Year 2025!!';
  document.getElementById('myAud').style.display = 'block';
  document.getElementById('myTime').style.fontSize = '6.5vw';


  const duration = 1 * 2000; // 持续 60 秒
  const end = Date.now() + duration;
  var triangle = confetti.shapeFromPath({ path: 'M0 10 L5 0 L10 10z' });
  // var scalar = 50;
  // var pineapple = confetti.shapeFromText({ text: '🥝', scalar });
  // var cold = confetti.shapeFromText({ text: '🍅', scalar });
  (function frame() {
    confetti({
      particleCount: 5,
      angle: 30,
      spread: 60,
      origin: { x: 0 }, // 从左侧发射
      shapes: [triangle]
    });

    confetti({
      particleCount: 5,
      angle: 150,
      spread: 60,
      origin: { x: 1 }, // 从右侧发射
      shapes: [triangle]
    });

    if (Date.now() < end) {
      requestAnimationFrame(frame);
    }
  })();

}
const updateTime = () => {
  time.value = countdown();
  if (countdown() == '0 0:00:00') {
    startCelebration(timerId);
  }

}

let timerId = setInterval(updateTime, 1000);



</script>
<style scoped>
.time {
  color: white;
  font-size: 12vw;
  max-width: 100%;
  padding: 0%;
  margin: 0%;

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#myAud {
  display: none;
  width: 100%;
}

#Audio {
  width: 40vw;
}

.words {
  color: white;
  font-size: 4.5vw;
  padding: 0%;
  margin: 0%;

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.adj {
  color: rgb(255, 255, 255);
  font-size: 6vw;
  max-width: 100%;
  word-wrap: break-word;
  /* 长单词换行 */
  overflow-wrap: break-word;
  /* 新版 CSS 的替代写法 */
  white-space: normal;
  /* 允许自动换行 */
  padding: 0%;
  margin: 0%;
}

.ininput {
  text-align: center;
  /* font-size: 5vw; */
  display: flex;
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中（如果需要） */
}

.bubutton {
  padding: 0;
  margin: 0;
  text-align: center;
  width: auto;
}

.aline {
  max-width: 100%;
  padding: 0%;
  margin: 0%;
}
</style>